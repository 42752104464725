














import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

function createTeacher() {
  return {
    department: null,
    reL_Department: null,
    firstName: "",
    lastName: "",
    initials: "",
  };
}

function getTabs(id: string | null) {
  const disabled = !(id) || (id === 'new');
  return [
    {
      title: "Stamdata",
      to: `/teachers/${id}`,
    },
    {
      title: "Semestre",
      to: `/teachers/${id}/terms`,
      disabled
    },
    {
      title: "Fag",
      to: `/teachers/${id}/subjects`,
      disabled
    },
  ];
}

export default Vue.extend({
  name: "teachers-edit",
  data: () => ({
    teacher: createTeacher(),
    firstName: null,
    lastName: null,
    selectedDepartment: null,
    tabs: [],
  }),
  beforeMount() {
    const id = this.$route.params.id as string | "new";

    if (id !== "new") {
      this.teacher = this.getTeacher(id);
      this.firstName = this.teacher.firstName;
      this.lastName = this.teacher.lastName;
    }

    this.setSelectedTeacher(this.teacher);

    this.tabs = getTabs(id);
  },
  computed: {
    ...mapGetters("teachers", ["state", "getTeacher"]),
    ...mapGetters("departments", ["departments"]),
  },
  methods: {
    ...mapActions("teachers", ["setSelectedTeacher"]),
  },
});
